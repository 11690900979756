// import { useAuth } from "configuration/AuthContext";
import { cwitoApi } from "configuration/AxiosConfig";


// Helper function to convert Base64 URL encoded string to Uint8Array
function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const RegisterPush = async (storedToken:string) => {
  // const { storedToken } = useAuth();
  if ("serviceWorker" in navigator && "PushManager" in window) {
    try {
      const registration = await navigator.serviceWorker.ready;
// console.log("token from storage is", localStorage.getItem("accessToken"))
      // Convert the Base64 URL encoded VAPID key to a Uint8Array
      const applicationServerKey = urlBase64ToUint8Array(
        process.env.REACT_APP_VAPID_PUBLIC_KEY as string
      );

      // Subscribe for push notifications
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey,
      });

      // Log the subscription object for debugging
      // console.log("Push subscription:", JSON.stringify(subscription, null, 2));

      // Send subscription to the backend
      const response = await cwitoApi.post(
        "/user/register-web-push-token",
        subscription,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      // Log the response from the backend
      console.log("web-push-token response:", response.data);
      console.log("Push notification subscription registered successfully.");
    } catch (error) {
      console.error("Error during push subscription registration:", error);
    }
  } else {
    console.warn("Push notifications are not supported by this browser.");
  }
};

export default RegisterPush;
