import { NavLink } from "react-router-dom";
import "./Support.css";
import arrowRight from "../../assets/arrowRight.svg";
// import { IconContext } from "react-icons";

type Props = {
  to: string;
  icon: any;
  title: string;
  subtitle: string;
};

const SidebarButton = (props: Props) => {
  return (
  
    <a href={props.to} target="blank" rel="noreferrer"
    
    className="flex justify-between gap-2 mt-6 hover:bg-[#d0cfcf]/50 items-center w-full md:w-[309px] rounded-lg  bg-[#F8F8F8] p-4 "
  >
          {props.icon}
          <div className="flex  justify-between whitespace-nowrap  items-center w-full  ">
            <div className="text-start ">
              <p className="font-bold text-sm ">{props.title}</p>
              <p className="text-xs">{props.subtitle}</p>
            </div>
            <img src={arrowRight} alt="asset icon" />

          </div>
        </a>
      
    
  );
};

export default SidebarButton;
