import mailIcon from "../../assets/mailIcon.svg";
import chatIcon from "../../assets/Chat.svg";
// import infoIcon from "../../assets/infoSquare.svg";

export const supportItems = [
  {
    to: "https://chat.whatsapp.com/Ikjuq4qZHtY8rCNPuELLsi",
    icon: <img src={chatIcon} alt="home Icon" />,
    title: "Need Help?",
    subtitle: "Chat us on Whatsapp",
  },
  {
    to: "mailto:support@cwito.com",
    icon: <img src={mailIcon} alt="Campaign Icon" />,
    title: "Send Mail",
    subtitle: "Give us a feedback",
  },
];
