import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";

import Support from "./support/Support";

type Props = {
  children: ReactNode;
};

const GrandLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const shouldRenderSidebar =
    location.pathname !== "/" &&
    location.pathname !== "/verify-email" &&
    location.pathname !== "/personal-details" &&
    location.pathname !== "/create-pin" &&
    location.pathname !== "/confirm-pin" &&
    location.pathname !== "/login" &&
    location.pathname !== "/forgot-password" &&
    location.pathname !== "/verify-code" &&
    location.pathname !== "/set-new-password" &&
    location.pathname !== "*" &&
    location.pathname !== "notifications";
  // location.pathname !== "/create-new-password" &&
  // location.pathname !== "/password-reset-successful";
  const shouldRenderSupport =
    location.pathname !== "/" &&
    location.pathname !== "/verify-email" &&
    location.pathname !== "/personal-details" &&
    location.pathname !== "/create-pin" &&
    location.pathname !== "/confirm-pin" &&
    location.pathname !== "/login" &&
    location.pathname !== "/forgot-password" &&
    location.pathname !== "/verify-code" &&
    location.pathname !== "/set-new-password" &&
    location.pathname !== "*" &&
    location.pathname !== "notifications";

  const sectionClassName =
    location.pathname === "/" ? "md:h-[100vh]" : "md:h-[100vh] ";

  return (
    <main className="w-full flex flex-col md:flex md:flex-row md:h-screen justify-start md:justify-between overflow-hidden">
      {shouldRenderSidebar && (
        <div className="w-[30%] md:pt-10">
          <aside>
            <Sidebar />
          </aside>
        </div>
      )}
      <div className="w-full md:w-[40%]">
        <section className={sectionClassName}>{children}</section>
      </div>

      {shouldRenderSupport && (
        <div className="w-[30%] hidden md:block md:pt-10">
          <aside>
            <Support />
          </aside>
        </div>
      )}
    </main>
  );
};

export default GrandLayout;
