// src/NotificationPermission.js
import React, { useEffect } from "react";
import RegisterPush from "../../pushNotification/RegisterPush";
import { useAuth } from "configuration/AuthContext";
 // Import the RegisterPush function

const NotificationPermission = () => {
  const {storedToken}=useAuth()
  useEffect(() => {
    const requestNotificationPermission = async () => {
      if ("Notification" in window && "serviceWorker" in navigator) {
        try {
          const permission = await Notification.requestPermission();
          if (permission === "granted") {
            console.log("Notification permission granted.");
            await RegisterPush(storedToken); // Call RegisterPush to handle subscription
          } else {
            console.log("Notification permission denied.");
          }
        } catch (error) {
          console.error("Error requesting notification permission:", error);
        }
      } else {
        console.log("Notification or Service Worker not supported");
      }
    };

    requestNotificationPermission();
  }, []);

  return null;
};

export default NotificationPermission;
