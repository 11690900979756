import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from "react";
import { cwitoApi } from "./AxiosConfig";
import { UserData, UserDetails } from "types/SigInTypes";
import { useNavigate } from "react-router-dom";
import { NotificationsEntity } from "types/NotificationTypes";

const AuthContext = createContext<any>(null);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<UserData>({} as UserData);
  const [userDetails, setUserDetails] = useState<UserDetails>(
    {} as UserDetails
  );
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorNotification, setErrorNotification] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [storedToken, setStoredToken] = useState<string | null>("");
  const [isWalletConfirmed, setIsWalletConfirmed] = useState<boolean>(false);
  const [notificationCount, setNotificationCount] = useState<any>();
  const [bankSelected, setBankSelected]=useState(false)
  const [allNotifications, setAllNotifications] = useState<
    NotificationsEntity[]
  >([]);

  const login = async (userData: UserData, email: string, password: string) => {
    const formData = {
      email: email,
      password: password,
    };

    try {
      setIsLoading(true);
      const response = await cwitoApi.post("/auth/signin", formData, {
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setIsLoading(false);
        setUser((prevUser) => ({ ...prevUser, ...response.data }));
        setUserDetails((prevUserDetails) => ({
          ...prevUserDetails,
          ...response.data,
        }));
        localStorage.setItem("accessToken", response.data.accessToken);
        setStoredToken(response.data.accessToken);
        navigate("/home");
      } else if (response.status === 401) {
        setIsLoading(false);
        setErrorNotification(true);
        setMessage("Something went wrong, please try again");
        setTimeout(() => {
          setErrorNotification(false);
        }, 3000);
      }
    } catch (error: any) {
      setIsLoading(false);
      if (error.response && error.response.data.statusCode === 401) {
        setIsLoading(false);
        setErrorNotification(true);
        setMessage(error.response.data.message);
        setTimeout(() => {
          setErrorNotification(false);
        }, 3000);
      } else {
        setIsLoading(false);
        setErrorNotification(true);
        setTimeout(() => {
          setErrorNotification(false);
        }, 3000);
      }
    }
  };

  // console.log("from context", localStorage.getItem("accessToken"))

  
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      setStoredToken(token);
    }
  }, []);

  const fetchUserData = useCallback(async () => {
    try {
      const response = await cwitoApi.get("/user", {
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.status === 200) {
        setUserDetails((prevUserDetails) => ({
          ...prevUserDetails,
          ...response.data.user,
        }));
      } else if (response.status === 401) {
        setErrorNotification(true);
        setMessage("Something went wrong, please try again");
        setTimeout(() => {
          setErrorNotification(false);
        }, 3000);
      }
    } catch (error) {
      // Handle error
    }
  }, []);
  // console.log("public key is", process.env.REACT_APP_VAPID_PUBLIC_KEY)
  


  useEffect(() => {
    if (storedToken) {
      fetchUserData();
    }
  }, [storedToken, fetchUserData]);

  const setToken = () => {
    setStoredToken(localStorage.getItem("accessToken"));
  };

  const setWallet = () => {
    setIsWalletConfirmed(true);
  };

  const getAllNotifications = async () => {
    try {
      setIsLoading(true);
      const response = await cwitoApi.get("/user/notifications", {
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
      });
      setIsLoading(false);
      setNotificationCount(response.data.unreadCount);
      setAllNotifications(response.data.notifications);
    } catch (error) {
      setIsLoading(false);
      setMessage("Cannot retrieve notifications at this time.");
      setErrorNotification(true);
      setTimeout(() => {
        setErrorNotification(false);
      }, 3000);
    }
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    setUser({} as UserData);
    setIsLoading(false);
    setUserDetails({} as UserDetails);
    setStoredToken(null);
    setIsWalletConfirmed(false);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        setToken,
        fetchUserData,
        logout,
        setWallet,
        getAllNotifications,
        notificationCount,
        allNotifications,
        isWalletConfirmed,
        storedToken,
        userDetails,
        isLoading,
        errorNotification,
        message,
        bankSelected,
        setBankSelected
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
