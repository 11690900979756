import { useEffect } from "react";
import Routers from "./Routers";
import "./App.css";
import NotificationPermission from "components/push/NotificationPermission";
import RegisterPush from "pushNotification/RegisterPush";
import { useAuth } from "configuration/AuthContext";
// import NotifyButton from "components/push/NotifyButton";

function App() {
  const {storedToken}=useAuth()
  useEffect(() => {
    // Ensure RegisterPush is correctly implemented and logs output
    const registerPush = async () => {
      // console.log("access token is", localStorage.getItem("accessToken"))
      try {
        await RegisterPush(storedToken);
        console.log('RegisterPush executed successfully');
      } catch (error) {
        console.error('Error executing RegisterPush:', error);
      }
    };
    
    registerPush();
  }, []);

  useEffect(() => {
    const ac = new AbortController();
    document.title = "Cwito";
    return function cleanup() {
      ac.abort();
    };
  }, []);

  return (
    <div className="App app-container">
      <NotificationPermission />
      {/* <NotifyButton /> */}
      <Routers />
    </div>
  );
}

export default App;
