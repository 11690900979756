import home from "../../assets/Home.svg";
import transactionIcon from "../../assets/transactionsIcon.svg";
import settingsIcon from "../../assets/settingsIcon.svg";

export const sideBarItems = [
  {
    to: "/home",
    icon: <img src={home} alt="home Icon" />,
    title: "Home",
  },
  {
    to: "/transactions",
    icon: <img src={transactionIcon} alt="Campaign Icon" />,
    title: "Transactions",
  },
  {
    to: "/settings",
    icon: <img src={settingsIcon} alt="Feeds Icon" />,

    title: "Settings",
  },
];
